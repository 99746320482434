<template>
  <div class="section container">
    <header class="modal-card-head">
      <p class="modal-card-title">Commande n°{{ idOrder }}</p>
    </header>
    {{ order.date }}
    <br>
    <br>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Prix avant réduction</p>
          <p class="title">{{ Number(totalPrice).toFixed(2) }} CHF</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Réduction</p>
          <p class="title">{{ this.discount }} CHF</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Prix total</p>
          <p class="title">{{ Number((totalPrice - discount)).toFixed(2) }} CHF</p>
        </div>
      </div>
    </nav>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Etat de la commande</p>
          <p v-if="order.status === 'created'" class="title">créée</p>
          <p v-if="order.status === 'available'" class="title">prête pour retrait</p>
          <p v-if="order.status === 'done'" class="title">terminée</p>
          <p v-if="order.status === 'cancel'" class="title">annulée</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Etat du paiement</p>
          <p v-if="order.payment === 'pending'" class="title">en attente</p>
          <p v-else class="title">effectué</p>
        </div>
      </div>
    </nav>
    <div v-show="wait">
    <section v-show="paid" class="hero">
      <div class="hero-body">
        <p class="title">Méthodes de paiement acceptées</p>
      </div>
      <p class="subtitle">
        Frais paypal équivalent à 0,55CHF + 3,4%
      </p>
      <div style="position: relative; z-index: 1;">
      <div ref="paypal"></div>
      </div>
    </section>
    </div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">Contenu de la commande</p>
      </div>
    </section>
    <div v-for="product in order.products" :key="product.id">
      <div class="columns is-vcentered">
        <div class="column">
          <img v-if="product.image_path" :src='imageURL(product.image_path)' alt="image of the product"
               style="height: 20em;">
          <img v-else src="@/assets/logo-arene.png" alt="image of the product">
        </div>
        <div class="column has-text-left">
        <strong>{{ product.name }}</strong><br>
        <span>{{ Number(product.price).toFixed(2) }} CHF / pièce</span><br>
        <span>{{ product.pivot.quantity }} {{ product.pivot.quantity > 1 ? 'pièces commandées' : 'pièce commandée' }}</span><br>
        <span>Sous-total : {{ Number(product.price * product.pivot.quantity).toFixed(2) }} CHF</span><br>
          <span>{{ product.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {imageMixin} from '@/mixins/image';
import notification from "@/mixins/notification";

export default {
  name: 'FormUpdateEvent',
  data: () => ({
    order: {},
    idOrder: null,
    loaded: false,
    totalPrice: 0,
    paid: true,
    wait: false,
    payment: null,
    discount: 0
  }),
  mixins: [imageMixin, notification],
  async beforeMount() {
    await this.$store.dispatch('getUser')
      .then((data) => {
        this.user = data;
        this.form = this.user;
      })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
    this.idOrder = this.$route.params.id;
    this.$store.dispatch('getOrder', this.idOrder)
      .then(data => {
        this.order = data
        this.discount = this.order.discount
        if (typeof this.discount !== 'number' || this.discount < 0) this.discount = 0
        this.order.date = new Date(this.order.date).toLocaleDateString()
        this.order.products.forEach(product => this.totalPrice = this.totalPrice + (product.price * product.pivot.quantity))
        if (this.order.payment !== 'pending') {
          this.paid = false
        }
        this.payment = this.order.payment
        if (this.payment === 'pending') {
          this.wait = true
        }
      })
      .catch(() => {
        this.showError('Impossible de charger la commande.')
      });
  },
  mounted: function () {
    const script = document.createElement('script')
    script.src = 'https://www.paypal.com/sdk/js?currency=CHF&client-id=AXzEHueFn4Lj8N15C4VEgAaVrqriil9pfITwCy4e0vOag2Nf74FkEIVj48z7yibf10JdXlVCHgl07QYc'
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },
  methods: {
    pay() {
      this.$store.dispatch('payOrder', this.order.id)
      this.order.payment = "to be verified"
      this.$toast.clear()
      this.$toast.open({
        message: 'Paiement effectué',
        type: 'warning',
        position: 'top'
      });
    },
    setLoaded: function () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Commande à ARENE DE DUEL numéro : ' + this.idOrder,
                  amount: {
                    currency_code: 'CHF',
                    value: Number((this.totalPrice - this.discount) * 1.034 + 0.55).toFixed(2)
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paid = false;
            this.pay();
          }
        })
        .render(this.$refs.paypal);
    }
  }
};
</script>

